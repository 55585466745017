import { reactive, readonly } from 'vue';
import { default as syncManager } from 'o365.serviceWorker.client.SyncManager.js';
import { default as messageBroker } from 'o365.serviceWorker.client.MessageBroker.js';

export const offlineStore = (() => {
    const AppStates = [
        'online',
        'offline'
    ];

    const state = reactive({
        appInitialized: false,

        appState: 'online',
        appHasServerConnection: false,
        
        appVersion: null,
        appSyncedAppVersion: null,

        lastSync: {},

        syncOptions: null
    });

    const initialize = async (appVersion, syncOptions) => {
        state.appVersion = appVersion;
        state.syncOptions = syncOptions;

        await loadState();

        state.appInitialized = true;
    };

    const startSync = async (syncOptionId) => {
        if (state.syncOptions.hasOwnProperty(syncOptionId) === false) {
            alert('Failed to start sync. Could not find sync options')
            return;
        }

        let syncOptions = state.syncOptions[syncOptionId];

        console.log(`Starting ${syncOptions.type} sync`);

        let syncProgress = await syncManager.startSync(syncOptions);

        if (syncProgress.hasError) {
            return;
        }

        state.lastSync[syncOptionId] = {
            appVersion: state.appVersion,
            syncDate: new Date()
        };

        await saveState();
    };

    const updateServerStatus = async () => {
        await saveState();

        setTimeout(updateServerStatus, 1000 * 5);
    };

    const updateAppState = async (newValue) => {
        if (AppStates.includes(newValue) === false) {
            console.error('Failed to change app state. Invalid value provided. Valid values: ', AppStates);
            return;
        }

        state.appState = newValue;

        await saveState();
    };

    const saveState = async () => {
        // TODO: Implement function
    };

    const loadState = async () => {
        // TODO: Implement function
    }

    updateServerStatus();

    return {
        state: readonly(state),
        initialize,
        startSync,
        updateAppState
    };
})();
